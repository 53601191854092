import React from 'react';

const Flat = ( { x, y } ) => (
  <path className={"flat-template"}
  strokeWidth="0.3"
  fill="black"
  stroke="none"
  strokeDasharray="none"
  fontFamily="Arial"
  fontSize="10pt"
  fontWeight="normal"
  fontStyle="normal"
  x="410"
  y="79.5"
  width="1"
  height="41"
  d="M358.6174 85M358.39851999999996 67.73584C358.45324 67.70848000000001,358.50795999999997 67.70848000000001,358.59004 67.70848000000001C358.83628 67.70848000000001,359.05516 67.81792,359.13723999999996 68.0368C359.16459999999995 68.14624,359.16459999999995 68.14624,359.16459999999995 74.52112L359.16459999999995 80.95072L359.19196 80.86864C360.23163999999997 79.99312,361.32604 79.55536000000001,362.36571999999995 79.55536000000001C362.77612 79.55536000000001,363.18652 79.61008,363.59691999999995 79.77424C364.82811999999996 80.18464,365.48476 81.33376,365.48476 82.70176000000001C365.48476 83.41312,365.26588 84.20656,364.85548 85C364.25356 86.0944,363.48747999999995 86.96992,361.35339999999997 88.88512L359.24667999999997 90.8824C358.86364 91.26544,358.78155999999996 91.26544,358.6174 91.26544C358.37116 91.26544,358.15227999999996 91.12864,358.0702 90.90976L358.04283999999996 90.80032L358.04283999999996 79.50064L358.04283999999996 68.14624L358.0702 68.0368C358.15227999999996 67.92736,358.26171999999997 67.7632,358.39851999999996 67.73584M361.62699999999995 81.41584C361.51756 81.36112,361.35339999999997 81.36112,361.24395999999996 81.36112C361.16188 81.36112,361.10715999999996 81.36112,361.05244 81.36112C360.34108 81.47056,359.7118 81.90832,359.27403999999996 82.61968L359.16459999999995 82.8112L359.16459999999995 86.01232L359.16459999999995 89.26816L359.24667999999997 89.15872C361.24395999999996 86.96992,362.55724 84.4528,362.55724 82.78384L362.55724 82.72912C362.55724 81.90832,362.28364 81.55264,361.62699999999995 81.41584"
  style={{transform: `translate3d(${x}px, ${y}px, 0px)`}}>{x}</path>
)

export default Flat;