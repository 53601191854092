export const chords = {
    C: [
        [65, 50, 40],
        [50, 40, 30],
        [40, 30, 15],
        [30, 15, 5],
        [15, 5, -5],
    ],
    Cs: [
        [50, 35, 25],
    ],
    D: [
        [60, 45, 35],
        [45, 35, 25],
        [35, 25, 10],
        [25, 10, 0],
        [10, 0, -10],
    ],
    E: [
        [65, 55, 40],
        [55, 40, 30],
        [40, 30, 20],
        [30, 20, 5],
        [20, 5, -5],
    ],
    F: [
        [60, 50, 35],
        [50, 35, 25],
        [35, 25, 15],
        [25, 15, 0],
        [15, 0, -10],
    ],
    G: [
        [55, 45, 30],
        [45, 30, 20],
        [30, 20, 10],
        [20, 10, -5],
        [10, -5, -15]
    ],
    A: [
        [60, 50, 40],
        [50, 40, 25],
        [40, 25, 15],
        [25, 15, 5],
        [15, 5, -10],
    ],
    B: [
        [65, 55, 45],
        [55, 45, 35],
        [45, 35, 20],
        [35, 20, 10],
        [20, 10, 0],
    ],
}